<template>
  <div
    style="
    padding: 15px;"
  >
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
            ONGKOS JALAN
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <div>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <!-- <router-link to="/expedition/makeorder"> -->

              <v-btn
                @click="addTravelCost"
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px;"
                :disabled="loading"
              >
                Tambah Data
              </v-btn>

              <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px; margin-left:10px;"
                :disabled="loading"
                @click="exportData"
              >
                Export
              </v-btn>
              <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px; margin-left:10px;"
                :disabled="loading"
                @click="importData"
              >
                Import
              </v-btn>
              <!-- </router-link> -->
              <div
                style="width: 120px; display: flex; flex-direction: row; margin-left:25px;"
              >
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 1, name: 'Delete' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="viewAction"
                ></v-select>
              </div>
              <div
                style="width: 180px; display: flex; flex-direction: row; margin-left:auto;"
              >
                <v-select
                  v-model="paramApi.company"
                  :items="dropdown.company"
                  @change="companyWatcher"
                  style="height:40px;"
                  label="Company"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-autocomplete
                  v-model="paramApi.customer"
                  :items="dropdown.customer"
                  @change="customerWatcher"
                  label="Customer"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-details
                  hide-no-data
                ></v-autocomplete>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-select
                  v-model="paramApi.vehicle"
                  :items="dropdown.vehicle"
                  @change="vehicleWatcher"
                  style="height:40px;"
                  label="Kendaraan"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
            </v-toolbar>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <div style="width: 180px; display: flex; flex-direction: row;">
                <v-select
                  v-model="paramApi.type"
                  :items="[
                    { id: 1, name: 'Isi' },
                    { id: 2, name: 'Kosong' }
                  ]"
                  @change="typeWatcher"
                  style="height:40px;"
                  label="Tipe Ongkos Jalan"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  outlined
                ></v-select>
              </div>
              <!-- <div
                style="width: 180px; display: flex; flex-direction: row; margin-left: auto;"
              >
                <v-select
                  v-model="paramApi.start_company"
                  :items="dropdown.company"
                  @change="startCompanyWatcher"
                  style="height:40px;"
                  label="Start Company"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div> -->
              <div
                style="margin-left:10px; margin-left: auto; width: 180px; display: flex; flex-direction: row;"
              >
                <v-autocomplete
                  v-model="paramApi.start_location"
                  :items="dropdown.startLocation"
                  :search-input.sync="dropdown.startLocationSearch"
                  @change="startLocationWatcher"
                  label="Lokasi Start"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-autocomplete
                  v-model="paramApi.sub_customer"
                  :items="dropdown.subCustomer"
                  :search-input.sync="dropdown.subCustomerSearch"
                  @change="subCustomerWatcher"
                  label="Sub customer"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-autocomplete
                  v-model="paramApi.destination"
                  :items="dropdown.destination"
                  :search-input.sync="dropdown.destinationSearch"
                  @change="destinationWatcher"
                  label="Destinasi"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
            </v-toolbar>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            height="60vh"
            :headers="headers"
            style="cursor: pointer;"
            :items="result"
            :loading="loading"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            show-select
            :single-select="true"
          >
            <template v-slot:[`item.vehicle_type.name`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.vehicle_type !== null ? item.vehicle_type.name : '-' }}
              </div>
            </template>
            <!-- <template v-slot:[`item.company`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{
                  item.company.name.slice(
                    item.company.name.lastIndexOf('(') + 1,
                    item.company.name.lastIndexOf(')')
                  )
                }}
              </div>
            </template> -->
            <template v-slot:[`item.customer.name`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.customer !== null ? item.customer.name : '-' }}
              </div>
            </template>
            <template v-slot:[`item.sub_customer.name`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.sub_customer !== null ? item.sub_customer.name : '-' }}
              </div>
            </template>
            <template v-slot:[`item.start_from_location.name`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{
                  item.start_from_location !== null
                    ? item.start_from_location.name
                    : ''
                }}
              </div>
            </template>
            <template v-slot:[`item.destination_location.name`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{
                  item.destination_location !== null
                    ? item.destination_location.name
                    : ''
                }}
              </div>
            </template>
            <template v-slot:[`item.rounding_total_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                Rp.
                {{ priceFormat(item.rounding_total_cost) }}
              </div>
            </template>
            <template v-slot:[`item.total_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ priceFormat(item.total_cost) }}
              </div>
            </template>
            <template v-slot:[`item.other_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ priceFormat(item.other_cost) }}
              </div>
            </template>
            <template v-slot:[`item.driver_rate`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.driver_rate }}
              </div>
            </template>
            <template v-slot:[`item.assistance_driver_rate`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.assistance_driver_rate }}
              </div>
            </template>
            <template v-slot:[`item.mel_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.mel_cost }}
              </div>
            </template>
            <template v-slot:[`item.load_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.load_cost }}
              </div>
            </template>
            <template v-slot:[`item.unload_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.unload_cost }}
              </div>
            </template>
            <template v-slot:[`item.toll_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.toll_cost }}
              </div>
            </template>
            <template v-slot:[`item.fuel_cost`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.fuel_cost }}
              </div>
            </template>
            <template v-slot:[`item.mileage`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.mileage }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="addDialog" full-screen persistent>
      <v-form
        v-if="form !== null"
        :disabled="loading || (form.act === 'update' && !isEdit)"
        lazy-validation
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative;"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Tambah / Edit
          </v-card-title>

          <v-card-text
            class="d-flex justify-center flex-column"
            style="margin-top:10px;"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
              >
                <div class="d-flex" style="padding: 0 10px;width:100%;">
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <div style="margin-left:auto;" v-if="form.act === 'update'">
                      <a
                        v-if="!isEdit"
                        href=""
                        @click.prevent="isEdit = true"
                        style="font-size:14px;"
                        class="d-flex flex-row"
                      >
                        <v-icon small color="#1976d2" style="margin-right:3px;">
                          mdi-application-edit-outline
                        </v-icon>
                        Edit data
                      </a>
                      <p
                        v-else
                        @click.prevent="isEdit = false"
                        style="font-size:14px; margin:0; color:red; cursor: pointer;"
                        class="d-flex flex-row"
                      >
                        Batal
                        <v-icon small color="red" style="margin-left:3px;">
                          mdi-close
                        </v-icon>
                      </p>
                    </div>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tipe Ongkos Jalan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.travel_cost_type"
                      :items="[
                        { id: 1, name: 'Isi' },
                        { id: 2, name: 'Kosong' }
                      ]"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.company"
                      :items="dropdown.company"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Customer
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.customer"
                      :items="dropdown.customer"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Start Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.start_from_company"
                      :items="dropdown.company"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi start
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="form.start_from_location"
                      :items="dropdown.startFromLocation"
                      :search-input.sync="dropdown.startFromLocationSearch"
                      label="Cari"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Sub Customer
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="form.sub_customer"
                      :items="dropdown.subCustomerLocation"
                      :search-input.sync="dropdown.subCustomerLocationSearch"
                      label="Cari"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Destinasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="form.destination_location"
                      :items="dropdown.destinationLocation"
                      :search-input.sync="dropdown.destinationLocationSearch"
                      label="Cari"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      hide-no-data
                      :disbaled="form.sub_customer === null"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tipe Kendaraan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.vehicle_type"
                      :items="dropdown.vehicle"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Bahan Bakar
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;"
                    ><v-select
                      v-model="form.fuel_type"
                      :items="dropdown.fuel"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      readonly
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Jarak Tempuh
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.mileage"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      BBM
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.fuel_cost"
                      dense
                      outlined
                      type="number"
                      readonly
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Muat Barang
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.load_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Bongkar
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.unload_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Toll
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.toll_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      MEL
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.mel_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Zona
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.zone"
                      :items="dropdown.zone"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Rate Supir
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.driver_rate"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Rate Kenek
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.assistance_driver_rate"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lain-lain
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.other_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Total Biaya
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.total_cost"
                      dense
                      outlined
                      readonly
                      type="number"
                    />
                  </v-col>
                </div> </v-col
              ><v-col cols="12" md="4" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Pembulatan Total Biaya
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.rounding_total_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click="closeDialog"
              color="error"
              text
              outlined
              type="reset"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
              :disabled="form.act === 'update' && !isEdit"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <TravelCostImport @loadPage="getDataFromApi" />
  </div>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'mstravelcost',
  components: {
    TravelCostImport: () => import('./components/Import.vue')
  },
  data: () => ({
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      page: 1,
      order_by: 'created_at',
      order_type: 'desc',
      type: 1,
      customer: null,
      company: null,
      start_company: null,
      start_location: null,
      destination: null,
      sub_customer: null,
      vehicle: null
    },
    addDialog: false,
    form: null,
    isEdit: false,
    options: {},

    headers: [
      // {
      //   text: 'Jenis',
      //   value: 'travel_cost_type',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Kendaraan',
        value: 'vehicle_type.name',
        align: 'left',
        sortable: false
      },
      // {
      //   text: 'Company',
      //   value: 'company',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Customer',
        value: 'customer.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Lokasi Start',
        value: 'start_from_location.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Sub Customer',
        value: 'sub_customer.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Destinasi',
        value: 'destination_location.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Jarak (km)',
        value: 'mileage',
        align: 'left',
        sortable: false
      },
      {
        text: 'BBM',
        value: 'fuel_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'Toll',
        value: 'toll_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'Muat',
        value: 'load_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'Bongkar',
        value: 'unload_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'MEL',
        value: 'mel_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'Rate Supir',
        value: 'driver_rate',
        align: 'left',
        sortable: false
      },
      {
        text: 'Rate Kenek',
        value: 'assistance_driver_rate',
        align: 'left',
        sortable: false
      },
      {
        text: 'Lain-lain',
        value: 'other_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'Total',
        value: 'total_cost',
        align: 'left',
        sortable: false
      },
      {
        text: 'Pembulatan',
        value: 'rounding_total_cost',
        align: 'left',
        sortable: false
      }
    ],
    enableWatcher: true,
    selectedData: [],
    actionValue: 0,
    dropdown: {
      company: [],
      customer: [],
      vehicle: [],
      fuel: [],
      zone: [],

      startLocation: [],
      startLocationSearch: null,
      startLocationSelect: null,

      startFromLocation: [],
      startFromLocationSearch: null,
      startFromLocationSelect: null,

      destination: [],
      destinationSearch: null,
      destinationSelect: null,

      destinationLocation: [],
      destinationLocationSearch: null,
      destinationLocationSelect: null,

      subCustomer: [],
      subCustomerSearch: null,
      subCustomerSelect: null,

      subCustomerLocation: [],
      subCustomerLocationSearch: null,
      subCustomerLocationSelect: null
    },
    rules: {
      companyRules: [],
      customerRules: [],
      vehicleTypeRules: [],
      startLocRules: [],
      destinationRules: [],
      mileageRules: [],
      roundingRules: []
    }
  }),
  async mounted() {
    this.getDataFromApi()
    this.initDropdown()
  },
  created() {},
  watch: {
    'dropdown.startLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.startLocationSelect &&
          this.dropdownStartLocation(val)
      } else {
        this.dropdown.startLocationSelect = null
        this.dropdown.startLocation = []
      }
    },
    'dropdown.startFromLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.startFromLocationSelect &&
          this.dropdownStartFromLocation(val)
      } else {
        this.dropdown.startFromLocationSelect = null
        this.dropdown.startFromLocation = []
      }
    },
    'dropdown.destinationSearch'(val) {
      console.log(val)
      if (val !== '') {
        val &&
          val !== this.dropdown.destinationSelect &&
          this.dropdownDestination(val)
      } else {
        this.dropdown.destinationSelect = null
        this.dropdown.destination = []
      }
    },
    'dropdown.destinationLocationSearch'(val) {
      console.log(val)
      if (val !== '') {
        val &&
          val !== this.dropdown.destinationLocationSelect &&
          this.dropdownDestinationLocation(val)
      } else {
        this.dropdown.destinationLocationSelect = null
        this.dropdown.destinationLocation = []
      }
    },
    'dropdown.subCustomerSearch'(val) {
      console.log(val)
      if (val !== '') {
        val &&
          val !== this.dropdown.subCustomerSelect &&
          this.dropdownSubCustomer(val)
      } else {
        this.dropdown.subCustomerSelect = null
        this.dropdown.subCustomer = []
      }
    },
    'dropdown.subCustomerLocationSearch'(val) {
      console.log(val)
      if (val !== '') {
        val &&
          val !== this.dropdown.subCustomerLocationSelect &&
          this.dropdownSubCustomerLocation(val)
      } else {
        this.dropdown.subCustomerLocationSelect = null
        this.dropdown.subCustomerLocation = []
      }
    },
    'form.vehicle_type'(v) {
      // try {
      if (this.form !== null) {
        if (v !== null) {
          if (v.fuel_type) {
            this.form.fuel_type = v.fuel_type
            const price =
              ((v.ratio_fuel * Number(this.form.mileage)) / v.ratio_km) *
              Number(v.fuel_type.price)
            this.form.fuel_cost = Math.round(price)
            return null
          }
        }

        this.form.fuel_type = null
        this.form.fuel_cost = 0
        return null
      }
      // } catch (error) {
      //   console.log(error)
      //   return null
      // }
    },
    'form.mileage'(v) {
      // try {
      if (this.form !== null) {
        const vehicle = this.form.vehicle_type
        if (v !== '') {
          if (vehicle !== null) {
            if (vehicle.fuel_type !== null) {
              const price =
                ((vehicle.ratio_fuel * Number(v)) / vehicle.ratio_km) *
                Number(vehicle.fuel_type.price)
              this.form.fuel_cost = Math.round(price)
              return null
            }
          }
        }

        this.form.fuel_cost = 0
        return null
      }
      // } catch (error) {
      //   console.log(error)
      //   return null
      // }
    },
    'form.fuel_cost'() {
      this.calculate()
    },
    'form.toll_cost'() {
      this.calculate()
    },
    'form.load_cost'() {
      this.calculate()
    },
    'form.unload_cost'() {
      this.calculate()
    },
    'form.mel_cost'() {
      this.calculate()
    },
    'form.other_cost'() {
      this.calculate()
    },
    'form.driver_rate'() {
      this.calculate()
    },
    'form.assistance_driver_rate'() {
      this.calculate()
    },
    'form.without_fuel_cost'() {
      this.calculate()
    }
  },
  methods: {
    viewAction(event) {
      switch (event) {
        case 1:
          this.deleteItem()
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    refreshPage() {
      this.getDataFromApi()
      this.initDropdown()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.filtered_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      var url = `${this.expedition}travel_cost/list?&type=${
        this.paramApi.type
      }&offset=${this.paramApi.offset * this.paramApi.limit}&limit=${
        this.paramApi.limit
      }&order_type=${this.paramApi.order_type}&order_by=${
        this.paramApi.order_by
      }`
      // if (this.paramApi.company_id !== null) {
      const company = `&company=${
        this.paramApi.company !== null ? this.paramApi.company : ''
      }`
      const customer = `&customer=${
        this.paramApi.customer !== null ? this.paramApi.customer : ''
      }`
      const vehicle = `&vehicle=${
        this.paramApi.vehicle !== null ? this.paramApi.vehicle : ''
      }`
      const start_company = `&start_company=${
        this.paramApi.start_company !== null ? this.paramApi.start_company : ''
      }`
      const start_location = `&start_location=${
        this.paramApi.start_location !== null
          ? this.paramApi.start_location
          : ''
      }`
      const destination = `&destination=${
        this.paramApi.destination !== null ? this.paramApi.destination : ''
      }`
      const sub_customer = `&sub_customer=${
        this.paramApi.sub_customer !== null ? this.paramApi.sub_customer : ''
      }`
      url =
        url +
        company +
        customer +
        vehicle +
        start_company +
        start_location +
        destination +
        sub_customer

      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
      this.dropdownVehicle()
      this.dropdownCustomer()
      this.dropdownFuel()
      this.dropdownZone()
    },

    async typeWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async companyWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async customerWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async vehicleWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async startCompanyWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async startLocationWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async destinationWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async subCustomerWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async dropdownCompany() {
      this.dropdown.company = []
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.company = arr)
          }
          return (this.dropdown.company = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownCustomer(v) {
      await axios
        .get(`${this.expedition}customer/dropdown`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.customer = res.data.data)
          }
          return (this.dropdown.customer = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.customer = [])
        })
    },
    // async dropdownSubCustomer(v) {
    //   await axios
    //     .get(`${this.expedition}sub_customer/dropdown`)
    //     .then(res => {
    //       this.loading = false
    //       if (res.data.status_code === '00') {
    //         return (this.dropdown.sub_customer = res.data.data)
    //       }
    //       return (this.dropdown.sub_customer = [])
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       return (this.dropdown.sub_customer = [])
    //     })
    // },
    async dropdownVehicle(v) {
      await axios
        .get(`${this.expedition}vehicle_type/dropdown`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.vehicle = res.data.data)
          }
          return (this.dropdown.vehicle = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.vehicle = [])
        })
    },
    async dropdownFuel() {
      await axios
        .get(`${this.expedition}fuel_type/dropdown`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.fuel = res.data.data)
          }
          return (this.dropdown.fuel = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.fuel = [])
        })
    },
    async dropdownZone() {
      await axios
        .get(`${this.expedition}zone/dropdown`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.zone = res.data.data)
          }
          return (this.dropdown.zone = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.zone = [])
        })
    },
    async dropdownStartLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.startLocation = res.data.data)
          }
          return (this.dropdown.startLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.startLocation = [])
        })
    },
    async dropdownDestination(v) {
      let url = `${this.expedition}location/dropdown?keyword=${v}`
      if (this.paramApi.sub_customer !== null) {
        url = url + `&sub_customer_id=${this.paramApi.sub_customer}`
      }
      await axios
        .get(url)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.destination = res.data.data)
          }
          return (this.dropdown.destination = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.destination = [])
        })
    },
    async dropdownStartFromLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.startFromLocation = res.data.data)
          }
          return (this.dropdown.startFromLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.startFromLocation = [])
        })
    },
    async dropdownDestinationLocation(v) {
      let url = `${this.expedition}location/dropdown?keyword=${v}`
      if (this.form.sub_customer !== null) {
        url = url + `&sub_customer_id=${this.form.sub_customer.id}`
      }
      await axios
        .get(url)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.destinationLocation = res.data.data)
          }
          return (this.dropdown.destinationLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.destinationLocation = [])
        })
    },
    async dropdownSubCustomer(v) {
      await axios
        .get(`${this.expedition}sub_customer/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.subCustomer = res.data.data)
          }
          return (this.dropdown.subCustomer = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.subCustomer = [])
        })
    },
    async dropdownSubCustomerLocation(v) {
      await axios
        .get(`${this.expedition}sub_customer/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.subCustomerLocation = res.data.data)
          }
          return (this.dropdown.subCustomerLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.subCustomerLocation = [])
        })
    },
    calculate() {
      if (this.form !== null) {
        const total =
          Number(this.form.fuel_cost) +
          Number(this.form.toll_cost) +
          Number(this.form.load_cost) +
          Number(this.form.unload_cost) +
          Number(this.form.mel_cost) +
          Number(this.form.other_cost) +
          Number(this.form.driver_rate) +
          Number(this.form.assistance_driver_rate) +
          Number(this.form.without_fuel_cost)

        this.form.total_cost = total
        this.form.rounding_total_cost = total
      }
    },
    rowClick(pItem) {
      this.setForm(pItem)

      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    addTravelCost() {
      this.setForm()
      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    submit() {
      this.rules = {
        companyRules: [v => !!v || 'Company is required'],
        customerRules: [v => !!v || 'Customer is required'],
        vehicleTypeRules: [v => !!v || 'Vehicle is required'],
        startLocRules: [v => !!v || 'Start Location is required'],
        destinationRules: [v => !!v || 'Destination is required'],
        mileageRules: [v => !!v || 'Mileage is required'],
        roundingRules: [v => !!v || 'Rounding Cost is required']
      }
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      console.log(this.form)
      const form = {
        act: this.form.act,
        travel_cost_type: this.form.travel_cost_type,
        customer_id: this.form.customer.id,
        sub_customer_id: this.form.sub_customer.id,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        vehicle_type_id: this.form.vehicle_type.id,
        start_from_location_id: this.form.start_from_location.id,
        destination_location_id: this.form.destination_location.id,
        fuel_type_id: this.form.fuel_type.id,
        mileage: this.form.mileage,
        fuel_cost: this.form.fuel_cost,
        toll_cost: this.form.toll_cost,
        load_cost: this.form.load_cost,
        unload_cost: this.form.unload_cost,
        mel_cost: this.form.mel_cost,
        driver_rate: this.form.driver_rate,
        assistance_driver_rate: this.form.assistance_driver_rate,
        without_fuel_cost: this.form.without_fuel_cost,
        other_cost: this.form.other_cost,
        total_cost: this.form.total_cost,
        rounding_total_cost: this.form.rounding_total_cost,
        zone_id: this.form.zone.id
      }

      let start_from_company_id = null
      let start_from_company_name = null
      if (this.form.start_from_company !== null) {
        start_from_company_id = this.form.start_from_company.id
        start_from_company_name = this.form.start_from_company.name
      }
      Object.assign(form, {
        start_from_company_id: start_from_company_id,
        start_from_company_name: start_from_company_name
      })

      console.log(form)
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      // if (this.form.act === 'add') {
      //   Object.assign(form, {
      //     id: Math.floor(Math.random() * 1000000)
      //   })
      // }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.expedition}travel_cost/save`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            const self = this
            setTimeout(() => {
              self.closeDialog()
              this.getDataFromApi()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    closeDialog() {
      this.$refs.entryForm.reset()
      this.form = null
      this.addDialog = false
    },
    async deleteItem() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin menghapus data ini sekarang',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.expedition}travel_cost/delete/${this.selectedData[0].id}`
            )
            .then(res => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.setAction()
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch(err => {
              console.log(err)
              this.showMsgDialog('error', err, false)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    searchEnter() {
      this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    setForm(xData) {
      const x = xData !== undefined
      let vehicle = null
      if (x) {
        vehicle = xData.vehicle_type
        Object.assign(vehicle, { fuel_type: xData.fuel_type })
      }
      this.form = {
        act: x ? 'update' : 'add',
        travel_cost_type: x ? xData.travel_cost_type : null,
        customer: x ? xData.customer : null,
        sub_customer: x ? xData.sub_customer : null,
        company: x ? xData.company : null,
        vehicle_type: vehicle,
        start_from_location: x ? xData.start_from_location : null,
        destination_location: x ? xData.destination_location : null,
        fuel_type: x ? xData.fuel_type : null,
        mileage: x ? xData.mileage : 0,
        fuel_cost: x ? xData.fuel_cost : 0,
        toll_cost: x ? xData.toll_cost : 0,
        load_cost: x ? xData.load_cost : 0,
        unload_cost: x ? xData.unload_cost : 0,
        mel_cost: x ? xData.mel_cost : 0,
        driver_rate: x ? xData.driver_rate : 0,
        assistance_driver_rate: x ? xData.assistance_driver_rate : 0,
        without_fuel_cost: x ? xData.without_fuel_cost : 0,
        other_cost: x ? xData.other_cost : 0,
        total_cost: x ? xData.total_cost : 0,
        rounding_total_cost: x ? xData.rounding_total_cost : 0,
        zone: x ? xData.zone : null
      }
      let start_from_company = null
      if (x) {
        start_from_company = {
          id: xData.start_from_company_id,
          name: xData.start_from_company_name
        }
      }
      Object.assign(this.form, { start_from_company: start_from_company })

      if (x) {
        Object.assign(this.form, { id: xData.id })
        this.dropdownStartFromLocation(xData.start_from_location.name)
        this.dropdownDestinationLocation(xData.destination_location.name)
        if (xData.sub_customer !== null) {
          this.dropdownSubCustomerLocation(xData.sub_customer.name)
        }
      }

      console.log(this.form)
    },

    importData() {
      setTimeout(() => {
        document.getElementById('travelCostImport').click()
      }, 200)
    },

    async exportData() {
      this.loading = true
      const url = `${this.expedition}travel_cost/list?&type=1&offset=0&limit=all&order_type=desc&order_by=created_at&company=&customer=&vehicle=&start_company=&start_location=&destination=`

      await axios
        .get(url)
        .then(res => {
          console.log(res)
          let selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              selectedData.push({
                id: response[i].id,
                zone: response[i].zone,
                travel_cost_type: response[i].travel_cost_type,
                company: response[i].company,
                customer: response[i].customer,
                vehicle_type: response[i].vehicle_type,
                start_from_company_id: response[i].start_from_company_id,
                start_from_company_name: response[i].start_from_company_name,
                start_from_location: response[i].start_from_location,
                sub_customer: response[i].sub_customer,
                destination_location: response[i].destination_location,
                fuel_type: response[i].fuel_type,
                mileage: response[i].mileage,
                fuel_cost: response[i].fuel_cost,
                toll_cost: response[i].toll_cost,
                load_cost: response[i].load_cost,
                unload_cost: response[i].unload_cost,
                mel_cost: response[i].mel_cost,
                other_cost: response[i].other_cost,
                driver_rate: response[i].driver_rate,
                assistance_driver_rate: response[i].assistance_driver_rate,
                without_fuel_cost: response[i].without_fuel_cost,
                total_cost: response[i].total_cost,
                rounding_total_cost: response[i].rounding_total_cost
              })
            }
            this.exportNow(selectedData)
          } else {
            selectedData = []
            this.loading = false
          }
          return null
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          return null
        })
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          id: selectedData[i].id,
          zone_id:
            selectedData[i].zone !== null ? selectedData[i].zone.id : '-',
          zone_name:
            selectedData[i].zone !== null ? selectedData[i].zone.name : '-',
          travel_cost_type: selectedData[i].travel_cost_type,
          company_id:
            selectedData[i].company !== null ? selectedData[i].company.id : '-',
          company_name:
            selectedData[i].company !== null
              ? selectedData[i].company.name
              : '-',
          customer_id:
            selectedData[i].customer !== null
              ? selectedData[i].customer.id
              : '-',
          customer_name:
            selectedData[i].customer !== null
              ? selectedData[i].customer.name
              : '-',
          start_from_company_id: selectedData[i].start_from_company_id,
          start_from_company_name: selectedData[i].start_from_company_name,
          start_from_location_id:
            selectedData[i].start_from_location !== null
              ? selectedData[i].start_from_location.id
              : '-',
          start_from_location_name:
            selectedData[i].start_from_location !== null
              ? selectedData[i].start_from_location.name
              : '-',
          start_from_location_odoo_id:
            selectedData[i].start_from_location !== null
              ? selectedData[i].start_from_location.odoo_id
              : '-',
          sub_customer_id:
            selectedData[i].sub_customer !== null
              ? selectedData[i].sub_customer.id
              : '-',
          sub_customer_name:
            selectedData[i].sub_customer !== null
              ? selectedData[i].sub_customer.name
              : '-',
          sub_customer_odoo_id:
            selectedData[i].sub_customer !== null
              ? selectedData[i].sub_customer.odoo_id
              : '-',
          destination_location_id:
            selectedData[i].destination_location !== null
              ? selectedData[i].destination_location.id
              : '-',
          destination_location_name:
            selectedData[i].destination_location !== null
              ? selectedData[i].destination_location.name
              : '-',
          destination_location_odoo_id:
            selectedData[i].destination_location !== null
              ? selectedData[i].destination_location.odoo_id
              : '-',
          vehicle_type_id:
            selectedData[i].vehicle_type !== null
              ? selectedData[i].vehicle_type.id
              : '-',
          vehicle_type_name:
            selectedData[i].vehicle_type !== null
              ? selectedData[i].vehicle_type.name
              : '-',
          vehicle_ratio_km:
            selectedData[i].vehicle_type !== null
              ? selectedData[i].vehicle_type.ratio_km
              : '-',
          vehicle_ratio_fuel:
            selectedData[i].vehicle_type !== null
              ? selectedData[i].vehicle_type.ratio_fuel
              : '-',
          fuel_type_id:
            selectedData[i].fuel_type !== null
              ? selectedData[i].fuel_type.id
              : '-',
          fuel_type_name:
            selectedData[i].fuel_type !== null
              ? selectedData[i].fuel_type.name
              : '-',
          fuel_type_price:
            selectedData[i].fuel_type !== null
              ? selectedData[i].fuel_type.price
              : '-',
          mileage: selectedData[i].mileage,
          fuel_cost: selectedData[i].fuel_cost,
          toll_cost: selectedData[i].toll_cost,
          load_cost: selectedData[i].load_cost,
          unload_cost: selectedData[i].unload_cost,
          mel_cost: selectedData[i].mel_cost,
          other_cost: selectedData[i].other_cost,
          driver_rate: selectedData[i].driver_rate,
          assistance_driver_rate: selectedData[i].assistance_driver_rate,
          without_fuel_cost: selectedData[i].without_fuel_cost,
          total_cost: selectedData[i].total_cost,
          rounding_total_cost: selectedData[i].rounding_total_cost
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      const date = new Date()
      XLSX.writeFile(wb, `ONGKOS_JALAN_${date.toLocaleDateString('id')}.xlsx`)
      this.loading = false
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    priceFormat(val) {
      if (val !== undefined) {
        if (val !== null) {
          if (val !== '') {
            return val.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
        }
      }
      return ''
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
